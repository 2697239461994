import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"

import Img from "gatsby-image"

const BlogPostPreview = ({ frontMatter }) => {

  const renderExcerpt = excerpt => {
    const maxLen = 300
    return excerpt.length > maxLen
      ? excerpt.substring(0, maxLen) + "..."
      : excerpt
  }
  return (
    <div className="blog-post-preview" id={frontMatter.id}>
      <Link to={frontMatter.path}>
        <div className="blog-post-preview__excerpt-overlay">
          <p className="paragraph">{renderExcerpt(frontMatter.excerpt)}</p>
        </div>
        <div className="blog-post-preview__content">
          <div className="blog-post-preview__featured-image">
            <div className="blog-post-preview__featured-image-wrapper">
              <Img fluid={frontMatter.featuredImage.childImageSharp.fluid} />
            </div>
          </div>
          <div className="blog-post-preview__text">
            <div className="blog-post-preview__header">
              <div className="blog-post-preview__heading">
                <h2 className="heading-secondary">{frontMatter.title}</h2>
              </div>
              <small className="blog-post-preview__post-time">
                {frontMatter.date} | {frontMatter.author.name}
              </small>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

BlogPostPreview.propTypes = {
  frontMatter: PropTypes.node.isRequired,
}

export default BlogPostPreview
