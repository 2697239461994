import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"

import { graphql, useStaticQuery } from "gatsby"
import BlogPostPreview from "../components/blog/blog-post-preview"

const Blog = () => {
  const data = useStaticQuery(query)
  const blogPost = data.blog.edges
  return (
    <Layout>
      <SEO
        title="Blog"
        description="Learn about you can make your business more secure and more productive."
      />
      <PageTitle title="Blog" />
      <div className="page-blog">
        <div className="page-blog__content-container">
          {blogPost.map(({ node }) => {
            return <BlogPostPreview frontMatter={node.frontmatter} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    blog: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "MMMM, DD, YYYY")
            tags
            excerpt
            author {
              id
              name
              bio
              homepage
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
